export default [
  // drivers
  {
    name: "sub_admin_drivers",
    path: "sub-admin/drivers/show",
    component: () => import("@/views/sub_admin/drivers/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },

  // drivers credit
  {
    name: "sub_admin_drivers_credit",
    path: "sub-admin/drivers/credit",
    component: () => import("@/views/sub_admin/drivers/credit/show"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },

    // drivers out_orders
    {
      name: "sub_admin_drivers_out_orders",
      path: "sub-admin/drivers/out_orders",
      component: () => import("@/views/sub_admin/drivers/outside_orders"),
      beforeEnter(to, from, next) {
        if (
          localStorage.getItem("accessToken") &&
          JSON.parse(localStorage.getItem("results")).type == "sub_admin"
        ) {
          next();
        } else {
          next("/");
        }
      },
    },

  // driver credit history
  {
    name: "sub_admin_drivers_credit_history",
    path: "sub-admin/drivers/credit/history/:id/:name",
    component: () => import("@/views/sub_admin/drivers/credit/balance"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },

  // drivers add
  {
    name: "sub_admin_drivers_add",
    path: "sub-admin/drivers/show/add",
    component: () => import("@/views/sub_admin/drivers/add"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  // drivers edit
  {
    name: "sub_admin_drivers_edit",
    path: "sub-admin/drivers/show/edit",
    component: () => import("@/views/sub_admin/drivers/edit"),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem("accessToken") &&
        JSON.parse(localStorage.getItem("results")).type == "sub_admin"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
];
